<template>
  <div id="app">
    <Header
        :urlParams="getParams"
        :osDetect="osDetector"
        :show="show"
    />
    <About
        :urlParams="getParams"
        :osDetect="osDetector"
        :show="show"
    />
    <Payment/>
    <Reviews/>
    <Instruction/>
    <Footer
        :urlParams="getParams"
        :osDetect="osDetector"
        :show="show"
    />
  </div>
</template>

<script>

import Header from "@/components/Header";
import About from "@/components/About";
import Payment from "@/components/Payment";
import Reviews from "@/components/Reviews";
import Instruction from "@/components/Instruction";
import Footer from "@/components/Footer";
import {getParams, osDetector, show} from "@/scripts/script";

export default {
  name: 'App',
  components: {
    Footer,
    Instruction,
    Reviews,
    Payment,
    About,
    Header

  },
  data() {
    return {}
  },
  metaInfo() {
    return {
      title: '① DuxCasino Mobile App ➤ DOWNLOAD Casino App And Play Mobile Slots l▷ €500 Bonus + 150 Free spins',
      meta: [
        {description: '➤ DuxCasino Mobile App Allows You To Play Your Favorite Casino Games On iPhone, Android. Get Welcome Bonus €500 Bonus + 150 Free Spins!'}
      ],
    };
  },
  computed: {
    getParams,
    osDetector
  },
  methods: {
    show,
  },
}
</script>

<style>
:root {
  --color-body: rgb(17, 0, 32);
  --color-text-main: #fff;
}
</style>