<template>
  <section class="Reviews">
    <div class="Container">
      <div class="TopText Reviews-TopText">Testimonials</div>
      <h2 class="Title Title-h2 Reviews-Title">OUR CLIENTS</h2>
      <div class="Reviews-Content">
        <div class="Reviews-Card" v-for="info in cardInfo" :key="info.id">
          <div class="Reviews-CardMember">
            <div class="Reviews-CardMemberBox">
              <div class="Reviews-CardMemberName">
                {{ info.name }}
              </div>
              <div class="Reviews-CardMemberDate">
                {{ info.date }}
              </div>
            </div>
          </div>
          <img :src="require(`@/assets/img/${info.rate}`)" alt="rating" class="Reviews-CardRating">
          <p class="Text Reviews-CardText" v-html="info.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Reviews",
  data() {
    return {
      cardInfo: [
        {
          name: "BORIS",
          date: "12.07.2020",
          rate: "four-full-star.svg",
          text: "A large variety of slots and live games. The support was very friendly and helped me to receive my withdrawal as fast as possible. The mobile app is so сonvenient to use and has surpassed all my expectations."
        },
        {
          name: "CHRISTOPHER",
          date: "20.12.2020",
          rate: "five-full-star.svg",
          text: "I accidentally stumbled upon this casino on the App Store and now I only play on my smartphone. Because of a nice Welcome bonus I won a jackpot! Hopefully more winnings ahead."
        },
        {
          name: "JANE",
          date: "02.02.2021",
          rate: "four-full-star.svg",
          text: "The best online casino mobile app ever with a cool design and a wide range of games from well-known providers. You can always find something interesting and special!"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Reviews {
  padding: 82px 0 5px;

  @media (min-width: $screen-l) {
    padding: 122px 0 102px;
  }

  &-Title {
    margin-bottom: 60px;
  }

  &-Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-Card {
    width: 100%;
    padding: 63px 48px 40px 50px;
    border: 2px solid #3C2C4B;
    border-radius: 40px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      width: 394px;
      height: 486px;
      padding: 73px 58px 20px 60px;
      margin-bottom: 0;
    }
  }

  &-CardMember {
    display: flex;
    align-items: center;
    margin-bottom: 52px;
  }

  &-CardMemberName {
    font-family: 'Rubik-Medium', sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 1.25;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &-CardMemberDate {
    font-family: 'Rubik-Regular', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    color: #6E5883;
  }

  &-CardRating {
    margin-bottom: 26px;
  }

  &-CardText {
    font-size: 18px;
    line-height: 1.66;
  }
}
</style>