<template>
  <footer class="Footer">
    <div class="Container">
      <div class="Footer-Content">
        <div class="Footer-ContentInfo">
          <h2 class="Title Title-h2 Footer-Title">Download<br/> the mobile app</h2>
          <button v-if="this.$props.osDetect === 'android' || this.$props.osDetect === 'desk'"
                  class="Btn Header-Button"
                  @click="this.$props.show"
          >Download
          </button>
          <a v-if="this.$props.osDetect === 'ios'"
             :href=this.$props.urlParams
             target="_blank"
             class="Btn Header-Button">
            Download
          </a>
        </div>
        <img :src="require(`@/assets/img/${footerImage}`)" alt="image" class="Footer-ContentImg">
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: "Footer",
  data() {
    return {
      footerImage: "footer-image.png"
    }
  },
  props: {
    urlParams: {
      type: String,
      required: true
    },
    osDetect: {
      type: String,
      required: true
    },
    show: {
      type: Function,
      required: true
    },
  },
}
</script>

<style scoped lang="scss">
.Footer {
  padding: 184px 0 134px;
  background-color: #8600FF;
  position: relative;

  @media (min-width: $screen-l) {
    padding: 104px 0 96px;
  }

  &-Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @media (min-width: $screen-l) {
      text-align: left;
    }
  }

  &-ContentInfo {
    width: 100%;
    @media (min-width: $screen-l) {
      max-width: 574px;
      width: 100%;
    }
  }

  &-Title {
    text-align: center;
    margin-bottom: 46px;

    @media (min-width: $screen-l) {
      text-align: left;
    }
  }

  &-ContentImg {
    position: absolute;
    width: 150px;
    top: 20px;
    right: 20px;

    @media (min-width: $screen-l) {
      position: relative;
      width: 486px;
    }
  }
}
</style>