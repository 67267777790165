<template>
  <section class="Payment">
    <div class="Container">
      <h3 class="Title Title-h3 Payment-Title">OVER <span>200</span> payments systems</h3>
      <div class="Payment-ImgBox">
        <img v-for="logo in logos" :key="logo.id"
            :src="require(`@/assets/img/${logo.logo}`)" alt="payment logo" class="Payment-Img">
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Payment",
  data() {
    return {
      logos: [
        {logo: "visa.svg",},
        {logo: "maestro.svg",},
        {logo: "skrill.svg",},
        {logo: "paysafe.svg",},
        {logo: "wirecard.svg",},
        {logo: "trustly.svg",},
        {logo: "sofort.svg",},
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Payment {
  padding: 81px 0 5px;

  &-Title {
    margin-bottom: 60px;

    span {
      color: rgba(254, 157, 32, 1);
    }
  }

  &-ImgBox {
    mix-blend-mode: normal;
    opacity: 0.8;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1023px;
    width: 100%;
    margin: 0 auto;

    @media (min-width: $screen-m) {
      flex-direction: row;
    }
  }

  &-Img {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      margin-bottom: 0;
    }
  }
}
</style>