<template>
  <section class="About">
    <div class="Container">
      <div class="TopText About-TopText">Mobile App</div>
      <h2 class="Title Title-h2 About-Title">
        NEW PROMOTIONS
        EVERY DAY
      </h2>
      <div class="About-Content">
        <div class="About-Info">
          <ul class="About-List">
            <li class="About-Item">
              Exclusive offers, generous bonuses, and big wins await you in the DuxCasino mobile app
            </li>
            <li class="About-Item">
              With weekly tournaments and lotteries!
            </li>
          </ul>
          <button v-if="this.$props.osDetect === 'android' || this.$props.osDetect === 'desk'"
                  class="Btn Header-Button"
                  @click="this.$props.show"
          >Download
          </button>
          <a v-if="this.$props.osDetect === 'ios'"
             :href=this.$props.urlParams
             target="_blank"
             class="Btn Header-Button">
            Download
          </a>
        </div>
        <img :src="require(`@/assets/img/${image}`)" alt="image" class="About-Img">
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "About",
  data() {
    return {
      image: "about-img.png",
    }
  },
  props: {
    urlParams: {
      type: String,
      required: true
    },
    osDetect: {
      type: String,
      required: true
    },
    show: {
      type: Function,
      required: true
    },
  },
}
</script>

<style scoped lang="scss">
.About {
  padding: 47px 0 5px;

  @media (min-width: $screen-l) {
    padding: 47px 0 119px;
  }

  &-Title {
    margin: 0 auto 40px;
    text-align: center;

    @media (min-width: $screen-l) {
      max-width: 608px;
      width: 100%;
      margin: 0 auto 4px;
    }
  }

  &-Content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    @media (min-width: $screen-l) {
      text-align: left;
    }
  }

  &-Info {
    width: 100%;

    @media (min-width: $screen-l) {
      max-width: 450px;
    }
  }

  &-List {
    margin-bottom: 44px;

    @media (min-width: $screen-l) {
      margin-bottom: 124px;
    }
  }

  &-Item {
    font-size: 18px;
    line-height: 1.66;
    padding: 35px 10px 0;
    position: relative;

    @media (min-width: $screen-l) {
      padding: 0 0 0 55px;
      font-size: 18px;
    }

    &:before {
      content: '';
      position: absolute;
      background-image: url("../assets/img/check.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 30px;
      height: 30px;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);

      @media (min-width: $screen-l) {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
      }
    }
  }

  &-Img {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
    }
  }

  li + li {
    margin-top: 22px;
  }
}
</style>