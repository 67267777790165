<template>
  <section class="Header">
    <div class="Container">
      <div class="Header-Inner">
        <div class="Header-Content">
          <img :src="require(`@/assets/img/${logo}`)" alt="logo" class="Header-Logo">
          <h1 class="Title Title-h1 Header-Title">DOWNLOAD THE DUXCASINO MOBILE APP</h1>
          <p class="Text Header-Text">
            DuxCasino is an online casino where players from around the world can enjoy their favourite games and plunge
            themselves into the online world of video slots, live casino, and more!
          </p>
          <button v-if="this.$props.osDetect === 'android' || this.$props.osDetect === 'desk'"
                  class="Btn Header-Button"
                  @click="this.$props.show"
          >Download
          </button>
          <a v-if="this.$props.osDetect === 'ios'"
             :href="this.$props.urlParams"
             target="_blank"
             class="Btn Header-Button">
            Download
          </a>
        </div>
        <img :src="require(`@/assets/img/${headerImage}`)" alt="phone" class="Header-Img">
        <img :src="require(`@/assets/img/${headerBg}`)" alt="bg-logo" class="Header-ImgBg">
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "Header",
  data() {
    return {
      logo: "dux-logo.svg",
      headerImage: "header-phone.png",
      headerBg: "header-img-bg.svg",
    }
  },
  props: {
    urlParams: {
      type: String,
      required: true
    },
    osDetect: {
      type: String,
      required: true
    },
    show: {
      type: Function,
      required: true
    },
  },

}
</script>

<style scoped lang="scss">
.Header {
  padding: 112px 0 60px;
  overflow: hidden;

  @media (min-width: $screen-l) {
    padding: 82px 0 119px;
  }

  .Container {
    position: relative;
  }

  &-Inner {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (min-width: $screen-m) {
      flex-direction: row;
      justify-content: space-between;
      padding-right: 114px;
    }
  }

  &-Content {
    max-width: 650px;
    width: 100%;
    text-align: center;

    @media (min-width: $screen-m) {
      text-align: left;
    }
  }

  &-Logo {
    margin-bottom: 34px;
  }

  &-Title {
    margin-bottom: 18px;
    text-align: center;

    @media (min-width: $screen-m) {
      text-align: left;
    }
  }

  &-Text {
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.6;

    @media (min-width: $screen-m) {
      margin-bottom: 20px;
      padding-right: 60px;
      font-size: 26px;
    }
  }

  &-Img {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
    }
  }

  &-ImgBg {
    position: absolute;
    top: -142px;
    right: -77px;
    z-index: -1;

    @media (min-width: $screen-l) {
      right: -177px;
    }
  }
}
</style>