import Modal from "@/components/Modal";

export function show() {
    this.$modal.show(
        Modal,
        {},
        {
            name: 'modal',
            shiftY: .5,
            shiftX: .5,
            width: 870,
            height: 'auto',
            scrollable: true,
            'adaptive': true,
        },
        {},
    );
}

export function osDetector() {
    const userAgent = navigator.userAgent;
    if (/iPad|iPhone|iPod/i.test(userAgent)) {
        return 'ios';
    }
    if(/Mobi|Android/i.test(userAgent)) {
        return 'android';
    }
    return 'desk'
}

export function getParams() {
    let params = window.location.search;
    return 'https://duxcasino.onelink.me/rZPu/app' + (params ? `${params}&pid=landing&campaign_id=dux` : '?pid=landing&campaign_id=dux')
}