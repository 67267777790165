<template>
  <div class="Modal">
    <div class="Modal-Close" @click="hide">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.53813 15.2601C1.95712 15.8296 1.01555 15.8292 0.435074 15.2591C-0.145402 14.689 -0.144969 13.7652 0.436042 13.1956L5.7363 7.99995L0.436042 2.8043C-0.144969 2.23476 -0.145402 1.3109 0.435074 0.740812C1.01555 0.170722 1.95712 0.170279 2.53813 0.739824L7.84037 5.93741L13.4619 0.426869C14.0429 -0.142676 14.9844 -0.142233 15.5649 0.427857C16.1454 0.997947 16.145 1.9218 15.564 2.49135L9.94444 7.99996L15.564 13.5086C16.145 14.0781 16.1454 15.002 15.5649 15.5721C14.9844 16.1421 14.0429 16.1426 13.4619 15.573L7.84037 10.0625L2.53813 15.2601Z"
            fill="white"/>
      </svg>
    </div>
    <div class="Modal-Content">
      <div class="Modal-Left">
        <div class="Modal-Item"
             v-for="item in contentInfo" :key="item.id"
        >
          <img v-if="osDetector !== item.platform"
               :src="require(`@/assets/img/${item.image}`)"
               alt="image"
               class="Modal-ItemImg">
          <p v-if="osDetector !== item.platform"
             class="Text Modal-ItemText"
             v-html="item.text"></p>
        </div>
        <a v-if="osDetector === 'android'"
           :href="getParams"
           target="_blank"
           class="Btn Modal-Btn">
          Download
        </a>
      </div>
      <div class="Modal-Right">
        <img :src="require(`@/assets/img/${logo}`)" alt="logo" class="Modal-Logo">
        <p class="Text Modal-Text">
          Scan QR code with your mobile, or copy
          the link if you are using mobile.
        </p>
        <img :src="require(`@/assets/img/${qrcode}`)" alt="" class="Modal-QrCode">
        <div v-if="osDetector !== 'android'"
             class="Modal-LinkBox">

          <button class="Modal-LinkCopy"
                  v-clipboard:copy="siteLink"
                  v-clipboard:success="onCopy"
          >
            <span>{{ cutSiteLink }}</span>
            <img :src="require(`@/assets/img/${iconCopy}`)" alt="icon" class="Modal-LinkCopyImg">
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--  </modal>-->
</template>

<script>
import {getParams, osDetector} from "@/scripts/script";

export default {
  name: "Modal",
  data() {
    return {
      logo: "dux-logo.svg",
      qrcode: "dux-qr-code.gif",
      iconCopy: "icon-copy.svg",
      siteLink: "https://duxcasino.onelink.me/rZPu/?pid=landing&campaign_id=dux",
      contentInfo: [
        {
          image: "download.svg",
          text: "Open the site on your mobile or computer and click to download the application",
          platform: "android",
        },
        {
          image: "qrcode.svg",
          text: "<span>Scan the QR code with mobile, or </span>copy the link to download the app.",
          platform: "android"
        },
        {
          image: "shield.svg",
          text: "Allow to install unknown apps this source."
        },
        {
          image: "settings.svg",
          text: "After downloading the application, follow the instructions on the screen.",
        }
      ]
    }
  },
  methods: {
    hide() {
      this.$modal.hide('modal');
    },
    onCopy () {
      alert('Successful copied: ' + this.siteLink)
    },
  },
  computed: {
    osDetector,
    getParams,
    cutSiteLink() {
      return this.siteLink.split('/').slice(0, 3).join('/')
    }
  }
}
</script>

<style lang="scss">
.vm--overlay {
  background-color: rgba(16, 0, 31, .9) !important;
}

.vm--modal {
  background-color: rgb(26, 10, 42) !important;

  @media (min-width: $screen-l) {
    overflow: visible !important;
  }
}

.Modal {
  width: 100%;
  height: 100%;
  background-color: rgb(26, 10, 42);
  border-radius: 6px;
  position: relative;

  @media (min-width: $screen-l) {
    &:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: #3C2C4B;
      top: 0;
      left: 392px;
    }
  }

  &-Close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    @media (min-width: $screen-xl) {
      top: 0;
      right: -216px;
    }
  }

  &-Content {
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-Left {
    width: 100%;
    padding: 8px 8px 20px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (min-width: $screen-l) {
      width: 392px;
      padding: 48px 48px;
      text-align: left;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: normal;
    }
  }

  &-Item {
    margin-bottom: 32px;
    width: 45%;

    &:last-child {
      margin-bottom: 0;
    }

    @media (min-width: $screen-l) {
      width: 100%;
    }
  }

  &-ItemImg {
    width: 44px;
    margin-bottom: 10px;

    @media (min-width: $screen-l) {
      width: 64px;
      margin-bottom: 20px;
    }
  }

  &-ItemText {
    font-size: 12px;
    line-height: 1.66;

    &:first-letter {
      text-transform: uppercase;
    }

    span {
      display: none;
    }

    @media (min-width: $screen-l) {
      font-size: 16px;

      span {
        display: block;
      }
    }
  }

  &-Right {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 60px 20px 20px;

    @media (min-width: $screen-l) {
      width: 477px;
      padding: 80px 55px 20px;
    }
  }

  &-Logo {
    margin-bottom: 20px;
    @media (min-width: $screen-l) {
      margin-bottom: 40px;
    }
  }

  &-Text {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
      font-size: 18px;
      line-height: 1.66;
      padding: 0 20px;
      margin-bottom: 80px;
    }
  }

  &-QrCode {
    display: none;

    @media (min-width: $screen-l) {
      display: block;
      margin-bottom: 116px;
    }
  }

  &-LinkBox {
    display: flex;
    align-items: center;
  }

  &-LinkCopy {
    font-size: 12px;
    line-height: 1.66;
    transition: .3s;
    color: var(--color-text-main);

    display: flex;
    align-items: center;

    &:hover {
      color: rgba(254, 157, 32, 1);
    }

    @media (min-width: $screen-l) {
      font-size: 18px;
    }

    span {
      margin-right: 15px;
      @media (min-width: $screen-l) {
        margin-right: 25px;
      }
    }
  }

  &-LinkCopyImg {
    width: 18px;

    @media (min-width: $screen-l) {
      width: 25px;
    }
  }

  &-Btn {
    margin: 50px 0 100px;
  }
}

</style>