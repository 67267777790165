<template>
  <section class="Instruction">
    <div class="Container">
      <div class="TopText Instruction-TopText">Instruction</div>
      <h2 class="Title Title-h2 Instruction-Title">HOW TO INSTALL</h2>
      <p class="Text Instruction-Text">
        To install the app make sure to allow downloads from unknown sources:
      </p>
      <div class="Instruction-Content">
        <div class="Instruction-ContentItem"
             v-for="item in contentInfo" :key="item.id"
        >
          <img :src="require(`@/assets/img/${item.image}`)" alt="image" class="Instruction-ContentImg">
          <p class="Text Instruction-ContentText" v-html="item.text"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Instruction",
  data() {
    return {
      contentInfo: [
        {image: "download.svg", text: "Open the site on your mobile <br/> or computer and click to download the application"},
        {image: "qrcode.svg", text: "Scan the QR code with mobile,<br/> or copy the link to download<br/> the app."},
        {image: "shield.svg", text: "Allow to install unknown<br/> apps this source."},
        {image: "settings.svg", text: "After downloading the application, follow the instructions on the screen."}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.Instruction {
  padding: 82px 0 100px;

  @media (min-width: $screen-l) {
    padding: 102px 0 140px;
  }

  &-Title {
    margin-bottom: 30px;
  }

  &-Text {
    font-size: 18px;
    line-height: 1.66;
    text-align: center;
    max-width: 520px;
    width: 100%;
    margin: 0 auto 80px;
  }

  &-Content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @media (min-width: $screen-l) {
      flex-direction: row;
    }
  }

  &-ContentItem {
    width: 276px;
    display: flex;
    flex-direction: column;
    align-items: center;

    font-size: 18px;
    line-height: 1.66;
    text-align: center;
    position: relative;
    margin-bottom: 40px;

    &:last-child {
      &:after {
        display: none;
      }
    }

    @media (min-width: $screen-l) {
      width: 296px;
      height: 261px;
      padding: 0 10px;
      margin-bottom: 0;

      &:after {
        content: '';
        position: absolute;
        width: 120px;
        height: 1px;
        background-color: #3c2c4b;
        top: 56px;
        right: -128px;
        transform: translate(-50%, 0);
      }
    }
  }

  &-ContentImg {
    margin-bottom: 15px;

    @media (min-width: $screen-l) {
      margin-bottom: 60px;
    }
  }
}
</style>